import React, {useState} from "react"
import { navigate } from '@reach/router'
import Link from './Link'
import moment from "moment"
import pic02 from '../assets/images/pic02.jpg'
import { slug } from "../lib/utils"


const EventFeed = ({events}) => {

    const date = new Date()
    const INCREMENT = 10
    const [ maxEventsShown, setmaxEventsShown ] = useState( INCREMENT )

    const incrementMaxEvents = (events) => {
        if(events.length >= maxEventsShown > events.length - INCREMENT) {
            setmaxEventsShown(events.length)
        }else if(maxEventsShown < events.length){
            setmaxEventsShown(maxEventsShown + INCREMENT)
        }else{
            setmaxEventsShown(INCREMENT)    
        }
    }

    const removeDuplicates = ( arr, entry ) => {
        const dupArr = []
        const finalArr = []
        arr.forEach( item => {
            if( item[entry] ){
                if( !dupArr.includes( item[entry] ) ){
                    dupArr.push( item[entry] )
                    finalArr.push( item )
                }
            } else {
                throw 'Entry is not a key in the object'
            }
        } )
        return finalArr
    }
    // const filteredEvents = removeDuplicates( events, 'title' )
    let sortedEvents = events.map( event => {
        if(event.repeated && event.start < new Date()) {
            const d = new Date();
            const dayOfWeek = event.start.getDay()
            d.setDate(d.getDate() + (dayOfWeek + 7 - d.getDay()) % 7); 
            d.setHours(event.start.getHours())
            d.setMinutes(event.start.getMinutes())
            d.setFullYear(d.getFullYear())
            return {
                ...event,
                start: d
            }
        }else{
            return event
        }
    })

    sortedEvents = sortedEvents.filter( event => event.start.getDate() >= (new Date()).getDate())
    sortedEvents.sort( (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
    const displayedEvents = sortedEvents.slice(0, maxEventsShown)
    const showMoreEventsText = maxEventsShown >= sortedEvents.length ? "Show Less" : "Show More"
    let latestMonth = null
    return (
        <section id="event-feed" className="main style1 special large"> 
                <div className="grid-wrapper">
                    <div className="col-12">
                        <header className="major">
                            <h2>Upcoming</h2>
                        </header>
                    </div>
                    {displayedEvents.map( event => {
                        const showMonth = latestMonth !== event.start.getMonth()
                        latestMonth = event.start.getMonth()
                        return (
                            <>
                            {showMonth && <div data-aos='fade-up' data-aos-duration='800' className="col-12 event-feed-month">{moment(event.start).format('MMMM')}</div>}
                            <div key={event.id} data-aos='fade-up' data-aos-duration='800' className="col-12 event-feed-item" onClick={ () => navigate( `/whats-on/${slug( event.title )}`, { state: { title: event.title, extra: { ...event } } } )}>
                                {/* <h2>29 APR</h2> */}
                                <div className="grid-wrapper">
                                {/* <h3 className='col-12'>{event.title}</h3> */}
                                <div className='col-4'>
                                    <img className='event-feed-img' src={event.image || pic02} alt="" />
                                </div>
                                <div className='col-8 event-feed-item-info'>
                                    <h3>{event.title}</h3>
                                    <div><b>{moment(event.start).format('ddd DD MMM YYYY')}</b> @ {moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}</div>
                                    <span>{event.location || "Church Building"}</span>
                                    <span style={{fontSize: '0.75em'}}>
                                        <p>{event.description && event.description.substring( 0, 150 )}</p>
                                    </span>
                                </div>
                                </div>
                            </div>
                            </>
                        )
                    })}
                    <div className="col-12 actions" style={{ paddingTop: '2em' }}><button className="button transparent-button" style={{ color: 'gray' }} onClick={() => incrementMaxEvents(sortedEvents)}>{showMoreEventsText}</button></div>
                </div>
            </section>
    )
}

export default EventFeed